/* App.css */
/* Remove default margin */
html, body {
    height: 100%;
    margin: 0;

}

/*front navbar dropdown disappear icon*/
.dropdown-toggle::after{
    display: none !important;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* 使內容區域自動填充剩餘空間 */
.main-content {
    flex: 1; 
}

/* 保證 footer 不會被壓縮，始終位於頁面底部 */
footer {
    flex-shrink: 0;
}