/* Top navigation bar styling */
.top-navbar {
    z-index: 100;
    background-color: #252b36;
}
#dropdown-user-icon:hover{
    color:white
}
.drop-custom{
    background-color: #252b36;
    color:white;
}
.drop-item-custom:hover{
    color:white !important;
    text-shadow: none;
    background-color: #afafafb2;
}

.drop-item-custom:active{
    color:white;
    text-shadow: none;
    background-color: #afafafb2;
}