.productContant {
    position: relative;
    overflow: hidden;
}
  
.productContant .hoverOverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7); /* 底色，帶透明度 */
    color: white; /* 文字顏色 */
    padding: 10px;
    opacity: 0;
    transition: opacity 0.3s ease; /* 過渡效果 */
}
  
.productContant:hover .hoverOverlay {
    opacity: 1; /* 懸停時顯示 */
}