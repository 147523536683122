/*home page*/

body{
    background-color: rgba(238, 238, 238, 0.3);
}

.section-title h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
}

.section-title h2:after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #eb5d1e;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.video-title{
    color:#202020;
}

  
.video-title-container {
    display: flex;
    align-items: center;
}

.vertical-line {
    width: 6px;
    height: 2.5em;
    background-color: #eb5c1e65; 
    margin-right: 0.5em; 
    margin-bottom: 0.5em;
}

.orange-btn {
    background-color: #ffa500;
    color: white;
    border: none;
    padding: 10px 20px; 
    text-align: center;
    text-decoration: none; 
    display: inline-block;
    font-size: 1.4rem; 
    margin: 4px 2px; 
    cursor: pointer;
    border-radius: 20px;
    width: 30%;
}
.orange-btn:hover {
    background-color: #bd9d63bb;
}

.product-name{
    font-size:2rem;
}

.homepage-logo-size {
    width: 100%;
    max-width: 300px;
    height: auto;
    opacity: 0.7;
    transition: opacity 0.3s ease;
}

.homepage-logo-size:hover {
    opacity: 1; 
}

/* 設置卡片標題的樣式 */
.card-title h4 {
  color: #333; /* 標題顏色 */
  font-size: 1.25rem; /* 標題大小 */
}

/* 設置圖標圈的樣式 */
.icon-circle {
  background-color: #FFA500; /* 橙色背景 */
  border-radius: 50%; /* 圓形 */
  width: 90px; /* 圖標背景大小 */
  height: 90px; /* 圖標背景大小 */
  margin: 20px auto; /* 上下外邊距，水平居中 */
  padding-top: 15px;
}

/* .icon-circle:hover {
  background-color: #cc8400;
} */
.card-intro{
    background-color: rgba(238, 238, 238, 0.1); 
}
/* .card-intro:hover{
    background-color: #e0e0e031;
} */
.card-intro:hover .icon-circle{
    background-color: #e49144c7; 
}

/* 圖標顏色和大小 */
.card-body svg {
  color: white; /* 圖標顏色 */
  width: 60px; /* 圖標大小 */
  height: 60px; /* 圖標大小 */
}

/*news 首頁 start*/
.news-txt-date{
    letter-spacing: 1px; 
    font-weight: bold;
}
.news-txt-tag{
    width: 5em;
    margin-top: 2px;
    background: #FFA500;
    color: #fff;
    font-size: 15px;
    text-align: center;
}
.news-txt-tag-en{
    width: 8em;
}
.news-txt-title{
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 3px; 
}
.news-moreBtn{
    border-color:#FFA500;
    color: #FFA500;
}
.news-moreBtn:hover{
    border-color:#ffa6006c;
    background-color: #ffa6006c;
}

.custom-news-card {
    background: linear-gradient(to left, #ffffff 50%, #fff9f0 50%);
    background-size: 300% 100%; /* 背景大小是兩倍寬度 */
    background-position: right bottom; /* 初始背景位置從右邊開始 */
    transition: background-position 0.5s ease, transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.custom-news-card:hover {
    background-position: left bottom; /* 當 hover 時，背景從右移動到左 */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15); /* 增加陰影效果 */
}
/*new 首頁 end*/

/*news 內頁 start*/
.bg-news-search{
    background-color: #f7f7f7;
    border: 1px solid rgb(219, 219, 219);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}
.news-search-btn{
    color: white;
    background-color: #eb5c1e;
}
.news-search-btn:hover{
    background-color: #eb5c1e6c;
}
.news-content{
    border-bottom: 1px dotted rgba(122, 119, 119, 0.527);
}
.news-link{
    color: rgb(68, 68, 68); 
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 2rem;
}
.news-link:hover {
    color: rgba(46, 45, 45, 0.8);
    text-decoration:underline;
    transition: transform 0.6s ease;

}
/*.news-txt-sm{
    font-size: 10pt;
}*/
/*news 內頁 end*/

/*image show */
.product-card {
    background-color: white;
    border: 1px solid transparent;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
    min-height: 20vh;
}
  
.product-card:hover {
    transform: translateY(-10px);
}

.image-container {
position: relative;
display: block;
overflow: hidden;
border-top-left-radius: 8px;
border-top-right-radius: 8px;
}
/*.image-container:hover .img-fluid {
transform: scale(1.1);
}*/
  
.product-info {
    padding: 20px;
    text-align: center;
}

/*our teams logo*/
.logoChoice{
    background: #a8a8a849;
}
.logoChoice{
    background: #a8a8a849;
}


/*about*/
.about-title{
    font-size: 40px;
    font-weight: 700;
    text-transform: capitalize;
    margin-top: 20px;
    line-height: 56px;
    position: relative;
}

.about-title::after {
    content: "";
    position: absolute;
    display: block;
    width: 60px;
    height: 3px;
    background: #eb5d1e;
    bottom: -10px;
}

.nav-horizontal{
    display: flex !important;
}

/*內頁的nav button*/
.nav-titleLink{
    background-color: #333;
    color:white;
}
.nav-titleLink:hover{
    background-color: #eb5c1e71;
    color:white;
    text-shadow:none;
}
.nav-pills .nav-titleLink.active{
    background-color: #eb5c1e;
    color:white;
    text-shadow:none;
}

/*首頁的nav button*/
.nav-titleLink-sm {
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 50px !important;
    font-size: 1rem;
    font-weight: 600;
    background-color: #333;
    /*transition: background-color 0.3s ease, color 0.3s ease;*/
}
  
.nav-titleLink-sm:hover {
    background-color: #eb5c1e71;
    color: #333;
}

.nav-horizontal-sm .nav-link.active {
    background-color: #FF6600; 
    color: #fff;
    border-radius: 50px;
}
  
.nav-horizontal-sm {
    margin-right: 1rem;
}

/*product category accordion*/
.btn-accordion{
    border-radius: 0px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    
}
.btn-accordion:hover{
    border-radius: 0px;
    background-color: rgba(235, 125, 51, 0.315);
}


/*product Detail*/
.justify-content-md-end {
    justify-content: end;
}

/*pdf size*/
.pdf-size{
    width: 100%;
    height: 60vh;
}

/*contact*/
.flex-contact-item {
    flex-grow: 1;  
    display: flex;
    align-items: center; 
    justify-content: left; 
}

.bg-dark-orange{
    background-color: #eb5c1e;
}
.bg-light-gray{
    background-color: rgba(228, 228, 228, 0.534);
}

.bg-contact{
    background-color: white;
    border:4px solid #eb5c1e;
} 



.custom-carousel .carousel-control-prev-icon,
.custom-carousel .carousel-control-next-icon {
  background-color: black; /* 修改箭頭顏色為黑色 */
}

@media (min-width: 768px) {
    .custom-gap {
      margin-right: 5px;
    }
}

@media (max-width: 768px) { 
    .justify-content-md-end {
        justify-content: start;
        margin-bottom: 1rem;
        
    }
    .btn-sm-print{
        --bs-btn-padding-y: 0.25rem;
        --bs-btn-padding-x: 0.5rem;
        --bs-btn-font-size: 0.875rem;
        --bs-btn-border-radius: var(--bs-border-radius-sm);
    }
    .pdf-size{
        height: 500px;
        width: 100%;
    }
}

@media (max-width: 1024px) {
    .custom-news-card .d-flex {
        flex-wrap: wrap; /* 在1024px寬度以下自動換行 */
    }
    
    .news-txt-date, .news-txt-tag, .news-txt-title {
        margin-bottom: 0.5rem; /* 在換行後增加底部間距 */
    }
    .news-stylesBtn {
        display: none;
    }
}