.headerFront {
    position: relative;
}
  
.headerBackground {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    filter: blur(0.5px);
    z-index: -1;
    background-color: black;
}
  
/* 面包屑导航的样式 */
.breadcrumbCustom {
    justify-content: center; 
    display: inline-block; 
}

.breadcrumbCustom .breadcrumbItemCustom a {
    color: white;
    transition: color 0.3s;
}

.breadcrumbCustom .breadcrumbItemCustom a:hover {
    color: white;
    text-decoration: none; 
}

.breadcrumbItemCustom + .breadcrumbItemCustom::before {
    color: white;
}