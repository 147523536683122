.header-front {
  position: relative;
  /*height: 30vh;*/
}

.header-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    filter: blur(0.5px);
    z-index: -1;
    background-color: black;
}

.breadcrumb-custom {
    justify-content: center; 
    display: inline-block; 
}


.breadcrumb-custom .breadcrumb-item a {
    color: white;
    transition: color 0.3s;
}

.breadcrumb-custom .breadcrumb-item a:hover {
    color: white;
    text-decoration: none; 
}

/*custom bootstrap (麵包屑)*/
a{
    text-decoration: none; 
}
.breadcrumb-item+.breadcrumb-item::before {
    color: white;
}
.breadcrumb-item.active {
    color: white;
    font-weight: bold;
}