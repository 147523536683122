

/* Main layout styling */
.sidebar-container {
    display: flex;
    min-height: 100vh; 
}
/* Side navigation bar styling */
.side-navbar {
    background-color: #babcbd;
    height: 100vh; 
    width: 400px; 
    transition: width 0.3s;
}


/* sidebar的縮放 */
.sidebar-expanded .side-navbar {
    width: 400px;
}
.sidebar-expanded .side-navbar .nav-link{
    display: block;
}
.sidebar-collapsed .side-navbar {
    width: 0px; 
}
.sidebar-collapsed .side-navbar .nav-link{
    display: none;
}

/*Accordion & nav-link (兩層&一層的樣式需要same)*/
.align-middle {
    vertical-align: middle;
}

/* Content styling */
.content {
    flex-grow: 1;
    padding: 20px;
}

.nav-item-custom{
    color:#252b36;
    text-shadow: none;
}

.nav-item-custom:hover{
    color:#252b36;
    background-color: #d8d8d88c !important;
    text-shadow: none !important;
}


/*bootstrap customer*/

/*若沒有".nav"這一段，側邊欄若有比較多的item就會往col放，先暫時註解*/
/*
.nav{
    display: block;
}*/
.navbar-nav {
    --bs-nav-link-color: white;
}
.nav-link{
    padding: 1rem 1.25rem;
}
.nav-item,.nav-subItem{
    color:#252b36;
}

.nav-subItem:hover{
    color: rgb(95, 95, 95)!important;
    text-shadow: 0px 0px 0px rgba(220, 128, 30, 0.459)!important;
}
.accordion{
    --bs-accordion-bg: transparent;
    --bs-accordion-border-color: transparent;
}
.btn-link{
    --bs-btn-color: rgba( 0,0,0 , 0.65);
    --bs-btn-hover-color: rgba( 0,0,0 , 0.65);
}
.btn-link:hover {
    color: rgba(0, 0, 0);
}
.btn:first-child:active{
    color: rgba( 0,0,0 , 0.65);
}

/*兩層縮放後，已選取樣式*/
.accordion-button:hover {
    background-color: #d8d8d88c;
    color: #252b36;
}
.accordion-button:not(.collapsed){
    background-color: #d8d8d88c;
    color: #252b36;
}
.accordion-button:focus {
    box-shadow: 0 0px 0px transparent;
}
/* Responsive adjustments for mobile view */
@media (max-width: 768px) {
    /*手機版sidebar呈現的樣子*/
    .sidebar-expanded .side-navbar {
        width: 50%;
    }
    .side-navbar {
        position: absolute; 
        width: auto; 
        height: 100vh; 
        z-index: 101; 
    }

}