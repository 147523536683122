/* assets/backPage.css */
.login-page {
    /*background: linear-gradient(135deg, #2c3e50 0%, #bdc3c7 100%);*/
    background: linear-gradient(135deg, #c07a25 0%, #cec5a9 100%);
    min-height: 100vh; /* 確保背景色覆蓋整個視口高度 */
    color: #ffffff; /* 設置文字顏色 */
}

.card {
    background: rgba(218, 218, 218, 0.377); 
    color: rgb(230, 228, 216); 
}

.social-icons i {
    margin: 0 10px; /* 設置圖標間距 */
    padding: 10px;
    color: #252b36; /* 設置圖標顏色 */
    border: 1px solid #252b36; /* 設置圖標邊框 */
    border-radius: 50%; /* 設置為圓形 */
}

.forgot-password {
    color: #252b36; /* 設置鏈接顏色 */
    text-decoration: none; /* 移除下劃線 */
}

.btn-primary {
    background-color: #0000006e; /* 設置按鈕背景色 */
    border: none; /* 移除邊框 */
}

.btn-primary:hover {
    background-color: #0000006e; 
}
.btn-primary:active {
    background-color: #bebebe; 
}

.btn-blue{
    background-color: rgb(27, 132, 255);
   
}

.custom-quill .ql-container {
    height: 400px; 
    color:black;
    font-size: 1rem;
    background-color: white;
}
/*Dashboard*/
.chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px; /* 调整图表容器的高度 */
}

/*backPEdit image styles*/
/* backPEdit image styles */
.imgUpload-div {
    height: 80px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #ccc; /* 添加邊框 */
}

.imgUpload-div img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain; /* 確保圖片在框框內適當縮放 */
}

/*backPdfFileUp*/
.fixed-size {
    width: 150px;
    height: 150px;
    object-fit: cover; 
  }