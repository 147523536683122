/*.language-toggle-btn {
    background-color: rgba(220, 128, 30, 1);
    border-color: rgba(220, 128, 30, 1);
    width: 100px;
    min-width: 100px;
}
.language-toggle-btn:hover {
    background-color: rgba(220, 128, 30, 0.658);
    border-color: rgba(220, 128, 30, 0.658);
}
.language-toggle-btn:focus {
    background-color: rgba(220, 128, 30, 0.658);
    border-color: rgba(220, 128, 30, 0.658);
}

*/
/*fix bootstrap*/
/*.nav-link {
    font-size: 1.1rem;
    transition: text-shadow 0.3s ease; 
}
  
.nav-link:hover{
    color:rgba(220, 128, 30, 1);
    text-shadow: 0px 2px 2px rgba(220, 128, 30, 0.459);
}

.active-link {
    color: rgba(220, 128, 30, 1) !important; 
    text-shadow: 0px 2px 2px rgba(220, 128, 30, 0.459);
}
.nav-item:hover {
    background-color: transparent;
    color: #252b36;
}
*/
.dropdown-menu {
    --bs-dropdown-bg: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
    border: 0 solid var(--bs-dropdown-border-color);
    border-radius: 0;
}
.dropdown-item{
    color:white;
    padding: 1rem 1rem;
}

.dropdown-item:hover{
    color:rgba(220, 128, 30, 1);
    text-shadow: 0px 2px 2px rgba(220, 128, 30, 0.459);
}
#nav-dropdown-product{
    padding-left: 16px;
    padding-right: 16px;
}
.dropdown-item.active, .dropdown-item:active {
    color:rgba(220, 128, 30, 1);
    text-shadow: 0px 2px 2px rgba(220, 128, 30, 0.459);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-color);
}
