.languageToggleBtn {
    background-color: rgba(220, 128, 30, 1);
    border-color: rgba(220, 128, 30, 1);
    width: 100px;
    min-width: 100px;
}
.languageToggleBtn:hover {
    background-color: rgba(220, 128, 30, 0.658);
    border-color: rgba(220, 128, 30, 0.658);
}
.languageToggleBtn:focus {
    background-color: rgba(220, 128, 30, 0.658);
    border-color: rgba(220, 128, 30, 0.658);
}


/*fix bootstrap*/
.navLink {
    font-size: 1.1rem;
    transition: text-shadow 0.3s ease; 
}
  
.navLink:hover{
    color:rgba(220, 128, 30, 1);
    font-size: 1.1rem;
    text-shadow: 0px 2px 2px rgba(220, 128, 30, 0.459);
    /* text-shadow: 0px 2px 2px rgba(220, 128, 30, 1); */
}

.activeLink {
    /* 重要性提高以覆盖默认样式 */
    color: rgba(220, 128, 30, 1) !important; 
    text-shadow: 0px 2px 2px rgba(220, 128, 30, 0.459);
    font-weight: bold !important;
}

.navItem{
    font-size: 1.1rem;
}

.navItem:hover {
    background-color: transparent;
    color: #252b36;
}

/*
.dropdownMenu {
    background-color: rgba(0, 0, 0, 0.9);
    border: 0 solid var(--bs-dropdown-border-color);
    border-radius: 0;
}
*/
.dropdownItem{
    color:white;
    padding: 1rem 1rem;
    white-space: normal; /* 允許換行 */
    word-wrap: break-word; /* 自動換行 */
    max-width: 200px; /* 可以限制文字的最大寬度，根據需要調整 */
}
.dropdownItem:hover{
    color:rgba(220, 128, 30, 1);
    text-shadow: 0px 2px 2px rgba(220, 128, 30, 0.459);
}
#nav-dropdown-product{
    padding-left: 16px;
    padding-right: 16px;
}
.dropdownItem.active, .dropdownItem:active {
    color:rgba(220, 128, 30, 1);
    text-shadow: 0px 2px 2px rgba(220, 128, 30, 0.459);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-color);
}

