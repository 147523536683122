.card-container {
    padding: 1rem; /* 添加一些內邊距 */
}

.product-card {
    width: 100%; /* 卡片寬度佔滿容器寬度 */
    margin-bottom: 1rem; /* 卡片間隔 */
    display: flex; /* 開啟 Flexbox 佈局 */
    flex-direction: column; /* 子元素垂直排列 */
    position: relative; /* 为伪元素定位做准备 */
    cursor: pointer; /* 指针样式表示这是可点击的 */
    overflow: hidden; /* 防止伪元素溢出卡片 */
}

.product-card::after {
    content: attr(data-hover-text); /* 使用 data 属性动态设置文本 */
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 166, 0, 0); /*初始背景颜色透明*/
    opacity: 0; /* 初始时完全透明 */
    transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out; /* 过渡效果 */
    color: white; /* 文本颜色 */
    font-size: 2rem; /* 文本大小 */
    pointer-events: none; /* 防止伪元素捕获鼠标事件 */
}

.product-card:hover::after {
    background-color: rgba(255, 255, 255, 0.363); /* 鼠标悬停时的背景颜色及透明度 */
    opacity: 1; /* 鼠标悬停时完全不透明 */
}

.product-card img {
    width: 100%; /* 圖片寬度佔滿卡片寬度 */
    height: 300px; 
    object-fit: contain; 
}


.product-card h5 {
    padding: 0 15px; /* 在標題周圍添加一些邊距 */
    text-align: center; /* 將標題置中 */
    margin-top: 10px; /* 標題上方的空間 */

}



/*custom bootstrap5*/
.card{
    --bs-card-border-color: transparent;
}